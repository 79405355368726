<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->

          <b-form-group
            label="Title"
            label-for="Title"
          >
            <b-form-input
              id="title"
              v-model="reverseForm.title"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- Name -->

          <b-form-group
            label="Date"
            label-for="Date"
          >
            <b-form-input
              id="date"
              v-model="reverseForm.date"
              type="date"
              trim
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <!-- Name -->

          <b-form-group
            label="Description"
            label-for="Description"
          >
            <b-form-textarea
              id="description"
              v-model="reverseForm.description"
              trim
            />
          </b-form-group>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserSite()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserSite()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {},
  data() {
    return {
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionsStatusId: [],
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const reverseForm = ref({
    })
    return {
      getValidationState,
      reverseForm,
    }
  },
  methods: {
    addUserSite() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        Object.keys(this.reverseForm).forEach(key => {
          if (Array.isArray(this.reverseForm[key])) {
            this.reverseForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, this.reverseForm[key])
          }
        })

        axios
          .post(`/accounting-entry/${this.$route.params.id}/reverse`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'Post Entries' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            const errorMes = decryptData(error.response.data.payload)
            if (errorMes) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${errorMes.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
